#navbar {
    backdrop-filter: blur(10px);
    box-shadow: inset 0 0 2000px rgba(14, 13, 13, 0.5);
}

html,
body {
    scrollbar-color: #686868 #141415
}

:root {
    --swiper-theme-color: #90427b;
}

.navLink {
    border-radius: 100px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 0.1s;
    opacity: 0.8;
    color: white;
}

.navLink:hover {
    opacity: 1;
    cursor: pointer
}


.active {
    border: solid white;
    opacity: 1 !important
}